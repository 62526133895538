import request from "./request";

//新增评论
export function commentAdd(data) {
    return request({
        url: '/project/comment',
        method: 'post',
        data
    })
}

//修改评论
export function commentPut(data) {
    return request({
        url: '/project/comment',
        method: 'put',
        data
    })
}

//获取评论  
export function getInfo(id) {
    return request({
        url: '/project/comment/' + id,
        method: 'get'
    })
}


//删除评论
export function commentDeltel(ids) {
    return request({
        url: '/project/comment/' + ids,
        method: 'delete'
    })
}

//导出评论
export function commentExport() {
    return request({
        url: '/project/comment/export',
        method: 'post'
    })
}

//导入评论
export function commentImportData(data) {
    return request({
        url: '/project/comment/importData',
        method: 'post',
        data
    })
}

//获取评论列表
export function commentList(query) {
    return request({
        url: '/project/comment/list',
        params: query
    })
}
