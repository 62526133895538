<template>
  <div>
    <div class="reviewedList" :class="isPdf ? 'largeSize' : 'smallSize'">
      <div class="title">流程</div>
      <div class="reviewed">
        <div style="display: flex">
          <img v-if="avatar" class="avatar" :class="isPdf ? 'largerRgion' : 'smallRgion'" :src="avatar" alt="" />
          <div v-else class="avatarBox" :class="isPdf ? 'largerRgion' : 'smallRgion'">
            {{ userName }}
          </div>
          <div class="status">
            <div>发起请求</div>
            <div style="font-weight: 600">
              {{ userName }}
            </div>
          </div>
        </div>
        <div class="time">
          {{ getTime(createTime) }}
        </div>
      </div>
      <div v-show="processList.length !== 0" class="broder"></div>

      <div v-for="(it, idx) in processList" :key="it.id">
        <div v-for="(item, index) in it.commentList" :key="item.id">
          <div class="reviewed">
            <div style="display: flex">
              <img v-if="item.avatar" class="avatar" :class="isPdf ? 'largerRgion' : 'smallRgion'" :src="item.avatar"
                alt="" />
              <div v-else class="avatarBox" :class="isPdf ? 'largerRgion' : 'smallRgion'">
                {{ item.userName }}
              </div>
              <div class="comment">
                <span style="color: #307ffd"> {{ item.userName }} </span>添加评论
              </div>
            </div>
            <div class="time">
              {{ getTime(item.createTime) }}
            </div>
          </div>

          <div class="broder" :class="processList.length - 1 == idx &&
            it.commentList.length - 1 == index
            ? 'notTail'
            : 'tail'
            ">
            <div class="leaveMessage" v-show="item.comment">
              {{ item.comment }}
            </div>
            <div style="display: flex;flex-wrap: wrap;">
              <div v-for="file in item.fileList" :key="file.url">
                <img style="width: 80px" src="../assets/icon/file.png" alt="" @click="reViewFile(file.url)" />
                <div style="width: 80px" @click="reViewFile(file.url)">
                  {{ file.fileName }}
                </div>
                <van-button round block hairline plain type="info" native-type="button"
                  style="width: 80px; margin: 10px 0; float: right" @click="downloadFile(file)"
                  size="mini">下载文件</van-button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="reviewed" v-show="it.userName !== null && it.userId !== null">
            <div style="display: flex">
              <img v-if="it.avatar" class="avatar" :class="isPdf ? 'largerRgion' : 'smallRgion'" :src="it.avatar"
                alt="" />
              <div v-else class="avatarBox" :class="isPdf ? 'largerRgion' : 'smallRgion'">
                {{ it.userName }}
              </div>
              <div class="status">
                <div>
                  {{ getReviewedStatus(it.status) }}
                </div>
                <div style="font-weight: 600">
                  {{ it.userName }}
                </div>
              </div>
            </div>
            <div class="time">
              {{ getTime(it.handleTime) }}
            </div>
          </div>
          <div class="broder" v-show="processList.length - 1 !== idx">
            <div class="leaveMessage" v-show="it.approvalComment">
              {{ it.approvalComment }}
            </div>
          </div>
        </div>
      </div>
      <div class="title" v-if="copyUserList.length > 0">抄送</div>
      <div v-for="(it, idx) in copyUserList" :key="it.id">
        <div>
          <div class="reviewed" v-show="it.userName !== null && it.userId !== null">
            <div style="display: flex">
              <img v-if="it.avatar" class="avatar" :class="isPdf ? 'largerRgion' : 'smallRgion'" :src="it.avatar"
                alt="" />
              <div v-else class="avatarBox" :class="isPdf ? 'largerRgion' : 'smallRgion'">
                {{ it.userName }}
              </div>
              <div class="status">
                <div>
                  {{ getReviewedStatus(it.status) }}
                </div>
                <div style="font-weight: 600">
                  {{ it.userName }}
                </div>
              </div>
            </div>
            <div class="time">
              {{ getTime(it.handleTime) }}
            </div>
          </div>

          <div class="broder" v-show="copyUserList.length - 1 !== idx"></div>
        </div>
      </div>
    </div>
    <div v-show="isReviewImg" style="
       position: fixed;top: 0;left:0;right:0;bottom:0;margin:auto;width: 100%;height: 100%;background: #323233;z-index: 1;overflow: auto;
      " @click="isReviewImg = false">
      <img :src="reViewImg" alt="" style="
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        " />
    </div>
    <!-- <van-field v-show="status == 3 && userId == belongUserId" v-model="idea.approvalComment" rows="1" autosize
      label="审核意见" type="textarea" placeholder="请输入审核意见" />

    <div v-show="status == 3 && userId == belongUserId" style="
        width: 100%;
        margin-top: 20px;
        padding: 20px 0;
        display: flex;
        justify-content: space-around;
      ">
      <van-button round block type="default" native-type="submit" style="width: 35vw"
        @click="SubmitEvent(0)">拒绝</van-button>
      <van-button round block type="info" native-type="submit" style="width: 35vw" @click="SubmitEvent(1)">同意</van-button>
    </div> -->
  </div>
</template>

<script>
import Vue from "vue";
import { timeChange, reviewedStatusText } from "../units/units";
import { Button, Dialog } from "vant";
// import { getToken } from "../units/auth";
Vue.use(Button);
Vue.use(Dialog);
export default {
  name: "processDetails",
  props: {
    list: {
      typeof: Array,
      default: function () {
        return [];
      },
    },
    copyList: {
      typeof: Array,
      default: function () {
        return [];
      },
    },
    avatar: {
      typeof: String,
      default: "",
    },
    // status: {
    //   //审核专用
    //   typeof: Number,
    //   default: null,
    // },
    createTime: {
      typeof: String,
      default: "",
    },
    userName: {
      typeof: String,
      default: "",
    },
    routerPath: {
      type: String,
      default: "/",
    },
    // belongUserId: {
    //   typeof: String,
    //   default: "",
    // },
    isPdf: {
      typeof: Boolean,
      default: false,
    },
  },
  data() {
    return {
      idea: {
        approvalComment: "",
        flag: null,
      },
      processList: [],
      copyUserList: [],
      // userId: "",
      isReviewImg: false, //是否预览图片
      reViewImg: "",
    };
  },
  watch: {
    list: {
      handler(newVal) {
        this.processList = newVal;
      },
    },
    copyList: {
      handler(newVal) {
        this.copyUserList = newVal;
      },
    },
  },
  mounted() {
    this.processList = this.list;
    this.copyUserList = this.copyList;
    // this.getUserId();
  },
  methods: {
    getTime(time) {
      return timeChange(time);
    },
    getReviewedStatus(s) {
      return reviewedStatusText(s);
    },
    // SubmitEvent(f) {
    //   Dialog.confirm({
    //     message: `是否${f == 0 ? "拒绝" : "同意"}`,
    //     confirmButtonColor: "#0092fe",
    //   }).then(() => {
    //     this.idea.flag = f;
    //     this.$emit("submitIdea", this.idea);
    //   });
    // },
    reViewFile(url) {
      if (
        url.indexOf(".jpg") !== -1 ||
        url.indexOf(".jpeg") !== -1 ||
        url.indexOf(".png") !== -1
      ) {
        this.isReviewImg = true;
        this.reViewImg = url;
        return;
      }
      this.$router.push({
        path: "/preview",
        query: { url: url, routerPath: this.routerPath },
      });
    },
    downloadFile(file) {
      fetch(file.url)
        .then((res) => res.blob())
        .then((blob) => {
          // 将链接地址字符内容转变成blob地址
          const a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          a.download = file.fileName; // 下载文件的名字
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a); // 下载完成移除元素
        });
    },
    // getUserId() {
    //   this.userId = getToken("userId");
    // },
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-cell__title {
  font-weight: 600;
  font-size: 16px;
  color: #2c3e50 !important;
}

.largeSize {
  font-size: 20px !important;

  .title {
    font-size: 22px;
  }

  .broder {
    margin-left: 60px;
  }
}

.largerRgion {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

.smallSize {
  font-size: 13px;

  .title {
    font-size: 16px;
  }
}

.smallRgion {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.reviewedList {
  padding: 20px 16px;
  background: #fff;
  overflow: auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.title {
  font-weight: 600;
  text-align: left;
  margin-bottom: 2vh;
}

.reviewed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .avatar {
    border-radius: 5px;
    margin-left: 20px;
  }

  .avatarBox {
    border-radius: 5px;
    background: #307ffd;
    margin-left: 20px;
    color: #e4e4e4;
    overflow: hidden;
  }

  .status {
    text-align: left;
    margin-left: 10px;
    margin-top: 5px;
  }

  .time {
    color: #b4b4b4;
  }

  .comment {
    margin-top: 10px;
    margin-left: 10px;
  }
}

.broder {
  width: 70vw;
  min-height: 40px;
  border-left: 6px solid #e4e4e4;
  padding-left: 25px;
  margin-left: 36px;
  margin-bottom: 10px;
}

.leaveMessage {
  width: 60vw;
  background: #f6f6f6;
  text-align: left;
  padding: 5px;
  line-height: normal;
  word-wrap: break-word;
  overflow: auto;
}

.tail {
  border-left: 6px solid #e4e4e4;
}

.notTail {
  border-left: unset;
}
</style>