<template>
    <div style="width: 90vw;background:#fff;padding: 0 16px 20px;">
        <div style="font-size: 1rem;font-weight: 600;margin: 2vh 0;">添加评论</div>
        <div class="content">
            <!--文本框   contenteditable表示元素是否可被用户编辑。如果可以，浏览器会修改元素的组件以允许编辑-->
            <div class="editor" ref="divRef" contenteditable @keyup="handkeKeyUp" @keydown="handleKeyDown"
                @input="getContent"></div>
            <!--选项-->
            <AtDialog v-if="showDialog" :visible="showDialog" :position="position" :queryString="queryString"
                @onPickUser="handlePickUser" @onHide="handleHide" @onShow="handleShow" :userList="userList"></AtDialog>
        </div>
        <van-checkbox v-model="notice">是否通知发起人</van-checkbox>
        <van-field name="uploader" label="附件">
            <template #input>
                <van-uploader v-model="uploader" @click-preview="viewFile" accept="file" upload-icon="plus"
                    :after-read="afterRead" :before-read="beforeRead" @delete="deleteFile" :preview-full-image="false" />
            </template>
        </van-field>
        <van-button round block type="info" native-type="button" style="width: 30vw;margin: auto;"
            @click="sendMessage">发送评论</van-button>
    </div>
</template>
<script>
import AtDialog from './atDialog.vue'

import { Uploader, Button, Toast, Checkbox, CheckboxGroup } from 'vant'
import Vue from 'vue'
Vue.use(Uploader)
Vue.use(Button)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)


import { fileUpload } from "../../api/api"
import { commentAdd } from '../../api/comment'
import { getAllUserList } from '../../api/api'

export default {
    name: 'sandBox',
    props: {
        formId: {
            typeof: String,
            default: ''
        },
        type: {
            typeof: String,
            default: ''
        },
        routerPath: {
            typeof: String,
            default: ''
        },
        promoterId: {
            typeof: String,
            default: ''
        }
    },
    components: { AtDialog },
    data() {
        return {
            node: '', // 获取到节点
            user: '', // 选中项的内容
            endIndex: '', // 光标最后停留位置
            queryString: '', // 搜索值
            showDialog: false, // 是否显示弹窗
            position: {
                x: 0,
                y: 0
            },// 弹窗显示位置
            queryForm: {
                belongId: null,
                commentType: null,//添加评论的详情类型
                comment: '',//评论内容
                fileList: [],
                remindIdList: []
            },
            notice: true,//是否通知发起人
            uploader: [], //附件
            uploading: false, //是否正在上传
            userList: [],
            nameList: [],
            // promoter: ''
        }
    },
    mounted() {
        this.getUserList()
    },
    methods: {
        //获取用户列表
        async getUserList() {
            await getAllUserList().then(res => {
                this.userList = res.data.rows.map(it => {
                    return {
                        name: it.name,
                        id: it.userId
                    }
                })
            })
        },
        // 获取光标位置
        getCursorIndex() {
            const selection = window.getSelection()
            return selection.focusOffset // 选择开始处 focusNode 的偏移量
        },
        // 获取节点
        getRangeNode() {
            const selection = window.getSelection()
            return selection.focusNode // 选择的结束节点
        },
        // 弹窗出现的位置
        getRangeRect() {
            const selection = window.getSelection()
            const winWidth = window.innerWidth || document.documentElement.clientWidth
                || document.body.clientWidth;
            const range = selection.getRangeAt(0) // 是用于管理选择范围的通用对象
            const rect = range.getClientRects()[0] // 择一些文本并将获得所选文本的范围
            const LINE_HEIGHT = 0.4
            return {
                x: winWidth - rect.x > 200 ? rect.x : rect.x - 190,
                y: rect.y * LINE_HEIGHT
            }
        },
        // 是否展示 @
        showAt() {
            const node = this.getRangeNode()
            if (!node || node.nodeType !== Node.TEXT_NODE) return false
            const content = node.textContent || ''
            const regx = /@([^@\s]*)$/
            const match = regx.exec(content.slice(0, this.getCursorIndex()))
            return match && match.length === 2
        },
        // 获取 @ 用户
        getAtUser() {
            const content = this.getRangeNode().textContent || ''
            const regx = /@([^@\s]*)$/
            const match = regx.exec(content.slice(0, this.getCursorIndex()))
            if (match && match.length === 2) {
                return match[1]
            }
            return undefined
        },
        // 创建标签
        createAtButton(user) {
            const btn = document.createElement('span')
            btn.style.display = 'inline-block'
            btn.dataset.user = JSON.stringify(user)
            btn.className = 'at-button'
            btn.contentEditable = 'false'
            btn.textContent = `@${user.name}`
            const wrapper = document.createElement('span')
            wrapper.style.display = 'inline-block'
            wrapper.contentEditable = 'false'
            const spaceElem = document.createElement('span')
            spaceElem.style.whiteSpace = 'pre'
            spaceElem.textContent = '\u200b'
            spaceElem.contentEditable = 'false'
            const clonedSpaceElem = spaceElem.cloneNode(true)
            wrapper.appendChild(spaceElem)
            wrapper.appendChild(btn)
            wrapper.appendChild(clonedSpaceElem)
            return wrapper
        },
        replaceString(raw, replacer) {
            return raw.replace(/@([^@\s]*)$/, replacer)
        },
        // 插入@标签
        replaceAtUser(user) {
            const node = this.node
            if (node && user) {
                const content = node.textContent || ''
                const endIndex = this.endIndex
                const preSlice = this.replaceString(content.slice(0, endIndex), '')
                const restSlice = content.slice(endIndex)
                const parentNode = node.parentNode
                const nextNode = node.nextSibling
                const previousTextNode = new Text(preSlice)
                const nextTextNode = new Text('\u200b' + restSlice) // 添加 0 宽字符
                const atButton = this.createAtButton(user)
                parentNode.removeChild(node)
                // 插在文本框中
                if (nextNode) {
                    parentNode.insertBefore(previousTextNode, nextNode)
                    parentNode.insertBefore(atButton, nextNode)
                    parentNode.insertBefore(nextTextNode, nextNode)
                } else {
                    parentNode.appendChild(previousTextNode)
                    parentNode.appendChild(atButton)
                    parentNode.appendChild(nextTextNode)
                }
                // 重置光标的位置
                const range = new Range()
                const selection = window.getSelection()
                range.setStart(nextTextNode, 0)
                range.setEnd(nextTextNode, 0)
                selection.removeAllRanges()
                selection.addRange(range)
            }
        },
        // 键盘抬起事件
        handkeKeyUp() {
            if (this.showAt()) {
                const node = this.getRangeNode()
                const endIndex = this.getCursorIndex()
                this.node = node
                this.endIndex = endIndex
                this.position = this.getRangeRect()
                this.queryString = this.getAtUser() || ''
                this.showDialog = true
            } else {
                this.showDialog = false
            }
        },
        // 键盘按下事件
        handleKeyDown(e) {
            if (this.showDialog) {
                if (e.code === 'ArrowUp' ||
                    e.code === 'ArrowDown' ||
                    e.code === 'Enter') {
                    e.preventDefault()
                }
            }
        },
        // 插入标签后隐藏选择框
        handlePickUser(user) {
            this.replaceAtUser(user)
            this.user = user
            if (!this.queryForm.remindIdList.includes(user.id)) {
                this.queryForm.remindIdList.push(user.id)
                this.nameList.push('@' + user.name)
            }
            this.showDialog = false
        },
        // 隐藏选择框
        handleHide() {
            this.showDialog = false
        },
        // 显示选择框
        handleShow() {
            this.showDialog = true
        },
        getContent() {
            let temp = JSON.parse(JSON.stringify(this.nameList))
            this.nameList = temp.filter((it, idx) => {

                if (!this.$refs.divRef.innerText.includes(it)) {
                    this.queryForm.remindIdList.splice(idx)
                } else {
                    return it
                }

            })
        },
        beforeRead(file) {
            if (file.type == 'application/msword') {
                Toast('请将.doc类型文件另存为改成.docx类型的文件后再进行上传（请注意:手动改后缀可能文件类型并没有变化）')
                return
            }
            //上传之前校验
            if (file.size > 30 * 1024 * 1024) {
                Toast("只允许上传30M的文件");
                return false;
            }
            return true;
        },
        async afterRead(file) {
            //文件读取完成后的回调函数
            file.status = "uploading";
            file.message = "上传中...";
            this.uploading = true;
            let fd = new FormData();
            fd.append("file", file.file);
            await fileUpload(fd).then((res) => {
                if (res.data.code == 200) {
                    let obj = {
                        fileName: res.data.originalName,
                        url: res.data.url,
                    };
                    this.uploader[this.uploader.length - 1].url = res.data.url; //给返回的文件添加url属性
                    this.uploader[this.uploader.length - 1].fileName =
                        res.data.originalName;
                    this.queryForm.fileList.push(obj);
                    file.status = "";
                    file.message = ""; //上传状态
                    this.uploading = false;
                } else {
                    file.status = "failed";
                    file.message = "上传失败";
                    this.uploading = false;
                }
            }).catch(err => {
                if (err) {
                    file.status = "failed";
                    file.message = "上传失败";
                    this.uploading = false;
                }
            });
        },
        deleteFile(file) {
            this.queryForm.fileList = this.queryForm.fileList.filter((it) => {
                return it.url !== file.url;
            });
        },
        viewFile(file) {
            if (file.url.indexOf('.jpg') !== -1 || file.url.indexOf('.jpeg') !== -1 || file.url.indexOf('.png') !== -1) {
                return
            }
            this.$router.push({
                path: "/preview",
                query: { url: file.url, routerPath: this.routerPath },
            });
        },
        sendMessage() {
            if (!this.queryForm.comment && !this.queryForm.fileList) {
                Toast.fail('请不要添加无内容的评论')
                return
            }
            if (this.notice && !this.queryForm.remindIdList.includes(this.promoterId)) {

                this.queryForm.remindIdList.push(this.promoterId)
            }
            this.queryForm.comment = this.$refs.divRef.innerText
            this.queryForm.belongId = this.formId
            this.queryForm.commentType = this.type



            commentAdd(this.queryForm).then(res => {
                if (res.data.code == 200) {
                    this.queryForm.comment = ''//评论内容
                    this.queryForm.fileList = []
                    this.queryForm.remindIdList = []
                    this.uploader = []
                    this.nameList = []
                    this.$refs.divRef.innerText = ''
                    Toast.success('评论添加成功')
                    this.$emit('succesSend')
                }
            })
        }
    }
}
</script>
  
<style scoped lang="less">
.content {
    font-family: sans-serif;
    margin-bottom: 10px;

    h1 {
        text-align: center;
    }
}

.editor {
    margin: 0 auto;
    width: 100%;
    height: 100px;
    background: #fff;
    border: 1px solid #e4e5e7;
    border-radius: 5px;
    text-align: left;
    box-sizing: border-box;
    padding: 10px;
    overflow: auto;
    line-height: 30px;

    &:focus {
        outline: none;
    }
}
</style>
.